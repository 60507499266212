import React from 'react';
import Seo from '../components/Seo';
import { graphql } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import '../styles/components/policy.scss';

export default function Terms({data}) {
	return (
		<>
			<Seo 
				bodyClasses="hide-current-page"
		    	siteTitle={data.wp.generalSettings.title}
				siteDescription={data.wp.generalSettings.description}
				title={data.wpPage.seo.title}
				metaDesc={data.wpPage.seo.metaDesc}
				focusKeywords={data.wpPage.seo.focuskw}
				opengraphTitle={data.wpPage.seo.opengraphTitle}
				opengraphDescription={data.wpPage.seo.opengraphDescription}
				opengraphImage={data.wpPage.seo.opengraphImage}
				opengraphImage={data.wpPage.seo.opengraphImage}
				twitterDescription={data.wpPage.seo.twitterDescription}
				twitterImage={data.wpPage.seo.twitterImage}
				twitterTitle={data.wpPage.seo.twitterTitle}
		    />

			<Layout>
		      	<Header 
		      		title={ data.wpPage.title } 
		      		url={ data.wpPage.uri } 
		      		id={ data.wpPage.id }
		      	/>
		      	<article className={ 'policy flex' }>
		      		<h1>{ data.wpPage.title }</h1>
			      	<div 
			      		className={ 'content' }
			      		dangerouslySetInnerHTML={{ __html: data.wpPage.content }}
			      	/>
		      	</article>
				<Footer />
			</Layout>
		</>
	);

}

export const query = graphql`
	query Terms {
	  wpPage(id: {eq: "cG9zdDo4OTM="}) {
	    id
	    title
	    content
	    uri
	    seo {
	      metaDesc
	      metaKeywords
	      metaRobotsNoindex
	      focuskw
	      opengraphAuthor
	      opengraphDescription
	      opengraphImage {
	        localFile {
	          childImageSharp {
	            fixed {
	              src
	            }
	          }
	        }
	      }
	      opengraphModifiedTime
	      opengraphPublishedTime
	      opengraphPublisher
	      opengraphSiteName
	      opengraphTitle
	      opengraphType
	      opengraphUrl
	      title
	      twitterDescription
	      twitterImage {
	        localFile {
	          childImageSharp {
	            fixed {
	              src
	            }
	          }
	        }
	      }
	      twitterTitle
	    }
	  }
	  wp {
	    generalSettings {
	      title
	      description
	    }
	  }
	}
`;